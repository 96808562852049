
const CourseExperience = ({user_reference}) => {

  return(
      <>
        <div>
            <div>
                {user_reference.length > 0 && user_reference.map((education,index)=>{

                    return <div className="text-white experience_section_div">
                        <div className="start_div">
                            <h2>{education.start_date} - {education.end_date}</h2>
                            <h5>{education.institution}</h5>

                        </div>
                        <div className="divider"></div>
                        <div className="end_div">
                            <h3>{education.name}</h3>
                            <p>{education.city} - {education.country}</p>
                            <p>{education.details}</p>
                        </div>
                    </div>
                })}

            </div>
        </div>
      </>
  )
}

export default CourseExperience;
