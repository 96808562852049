import Experience from "./components/Experience";
import LanguagesExperience from "./components/LanguagesExperience";
import CodingExperience from "./components/CodingExperience";
import KnowledgeExperience from "./components/KnowledgeExperience";
import EducationExperience from "./components/EducationExperience";
import AchievementsExperience from "./components/AchievementsExperience";
import CertificateExperience from "./components/CertificateExperience";
import ReferenceExperience from "./components/ReferenceExperience";
import {useSelector} from "react-redux";
import CourseExperience from "./components/CourseExperience";
import MainInterest from "./components/MainInterest";
import TechnicalExperience from "./components/TechnicalExperience";
import Hobbies from "./components/Hobbies";
import {useEffect, useState} from "react";
import ReactHelmet from "../../../../utility/ReactHelmet";
const Resume = () => {

    const {userAboutProfileDetails, address, user_coding_skills,
        user_education,
        user_achievements,
        user_certificates,
        user_reference,
        user_soft_skills,
        user_internships,
        user_courses,
        user_interest,
        user_technical_skills,
        portfolio_details,
        user_hobbies,
        user_experience, user_language, user_knowledge} = useSelector(state => state.user);

    const {
        showExperience,
        showEducation,
        showAchievement,
        showCertificate,
        showReference,
        showInternship,
        showCourse,
        showAbilities,
        showInterest,
        showSoftSkills,
        showLanguage,
        showCodingSkills,
        showKnowledge,
        showTechnicalSkills,
        showHobbies,
    } = useSelector(state => state.user.showContentStatus);

    const [total_experience, setTotalExperience] = useState(0);

    useEffect(()=>{
        if(portfolio_details.length > 0){
            setTotalExperience(portfolio_details[0].total_experience);
        }
    },[portfolio_details]);

    return(
        <>

            <ReactHelmet page_title="Resume"/>
            <div className="page_section_main_div resume_back_div">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="page_title">Resume</h2>
                        <p className="text-white"><b>{total_experience}</b> Years of Experience</p>
                    </div>
                    <div className="col-md-12 pb-4 pt-3">

                        <div className="row">

                            <div className={`col-md-${showAbilities ? '8' : '12'} pl-4 pr-4`}>
                                {showExperience === true &&
                                    <div>
                                        <div>
                                            <div>
                                                <h2 className="page_title experience_section_title">Experience</h2>
                                            </div>
                                        </div>
                                        <Experience user_experience={user_experience}/>
                                    </div>
                                }


                                {showInternship === true &&
                                    <div>
                                        <div>
                                            <div>
                                                <h2 className="page_title experience_section_title">Internship</h2>
                                            </div>
                                        </div>
                                        <Experience user_experience={user_internships}/>
                                    </div>
                                }


                                {showEducation === true &&
                                    <div>
                                        <div>
                                            <div>
                                                <h2 className="page_title experience_section_title">Education</h2>
                                            </div>
                                        </div>
                                        <EducationExperience user_education={user_education}/>
                                    </div>
                                }


                                {showAchievement === true &&
                                    <div>
                                        <div>
                                            <div>
                                                <h2 className="page_title experience_section_title">Achievements</h2>
                                            </div>
                                        </div>
                                        <AchievementsExperience user_achievements={user_achievements}/>
                                    </div>
                                }


                                {showCertificate === true &&
                                    <div>
                                        <div>
                                            <div>
                                                <h2 className="page_title experience_section_title">Certificate</h2>
                                            </div>
                                        </div>
                                        <CertificateExperience user_certificates={user_certificates}/>
                                    </div>
                                }

                                {showCourse === true &&
                                    <div>
                                        <div>
                                            <div>
                                                <h2 className="page_title experience_section_title">Course</h2>
                                            </div>
                                        </div>
                                        <CourseExperience user_reference={user_courses}/>
                                    </div>
                                }

                                {showReference === true &&
                                    <div>
                                        <div>
                                            <div>
                                                <h2 className="page_title experience_section_title">Reference</h2>
                                            </div>
                                        </div>
                                        <ReferenceExperience user_reference={user_reference}/>
                                    </div>
                                }
                            </div>


                            {showAbilities === true &&
                                <div className={`col-md-${showAbilities ? '4' : '12'} pl-4 pr-4`}>

                                    {showInterest === true &&
                                        <div className="mb-4">
                                            <div>
                                                <div>
                                                    <h2 className="page_title experience_section_title mb-2 mt-5">Interests</h2>
                                                </div>
                                            </div>
                                            <MainInterest user_interest={user_interest}/>
                                        </div>
                                    }


                                    {showLanguage === true &&
                                        <div>
                                            <div>
                                                <div>
                                                    <h2 className="page_title experience_section_title mb-4 mt-5">Languages</h2>
                                                </div>
                                            </div>

                                            <LanguagesExperience user_language={user_language}/>
                                        </div>
                                    }

                                    {showSoftSkills === true &&
                                        <div>
                                            <div>
                                                <div>
                                                    <h2 className="page_title experience_section_title mb-4 mt-3">Soft Skills</h2>
                                                </div>
                                            </div>

                                            <MainInterest user_interest={user_soft_skills}/>
                                        </div>
                                    }




                                    {showCodingSkills === true &&

                                        <div>
                                            <div>
                                                <div>
                                                    <h2 className="page_title experience_section_title mb-4 mt-5">Coding Skills</h2>
                                                </div>
                                            </div>

                                            <CodingExperience user_coding_skills={user_coding_skills}/>
                                        </div>
                                    }

                                    {showKnowledge === true &&
                                        <div>
                                            <div>
                                                <div>
                                                    <h2 className="page_title experience_section_title mb-2 mt-5">Knowledge</h2>
                                                </div>
                                            </div>

                                            <KnowledgeExperience user_knowledge={user_knowledge}/>
                                        </div>
                                    }



                                    {showTechnicalSkills === true &&
                                        <div>
                                            <div>
                                                <div>
                                                    <h2 className="page_title experience_section_title mb-2 mt-5">Technical Skills</h2>
                                                </div>
                                            </div>

                                            <TechnicalExperience user_coding_skills={user_technical_skills}/>
                                        </div>
                                    }

                                    {showHobbies === true &&
                                        <div>
                                            <div>
                                                <div>
                                                    <h2 className="page_title experience_section_title mb-2 mt-5">Hobbies</h2>
                                                </div>
                                            </div>

                                            <Hobbies hobbies={user_hobbies}/>
                                        </div>

                                    }


                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Resume;
