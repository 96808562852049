import Info from "./sidebar_components/Info";
import Languages from "./sidebar_components/Languages";
import Coding from "./sidebar_components/Coding";
import Knowledge from "./sidebar_components/Knowledge";
import {userAvatar, userDesignation, userName} from "../config/Constant";
import {toggleSidebar} from "../utility/LayoutSetting";
import {useSelector} from "react-redux";
const Sidebar = () => {

    const {userAboutProfileDetails, address, user_coding_skills, user_language, socialLinks} = useSelector(state => state.user);

    const {
        showSoftSkills,
        showLanguage,
        showKnowledge,
    } = useSelector(state => state.user.showContentStatus);

    return(
        <>
            <div>
                <div className="sidebar_section">
                    <div>
                        <a className="art-info-bar-btn text-white side_bar_close" onClick={()=>toggleSidebar()}>
                            <i className="fas fa-close"></i>
                        </a>
                    </div>
                    <div>
                        <div className="">

                            <div className="main_profile_div">
                                <div className="text-center p-3">
                                    <img src={userAboutProfileDetails.avatar_image} className="side_bar_profile_image"/>
                                    <h3 className="side_bar_profile_name">{userAboutProfileDetails.user_name}</h3>
                                    <p className="side_bar_profile_des">{userAboutProfileDetails.designation}</p>
                                </div>
                            </div>



                            <div>
                                <div className="list_div_main">

                                    <div>
                                        <div className="m-4">
                                            <Info address={address} />
                                        </div>


                                        {showLanguage === true &&
                                            <div className="m-4">
                                                <Languages user_language={user_language}/>
                                            </div>
                                        }

                                        {showSoftSkills === true &&
                                            <div className="m-4">
                                                <Coding user_coding_skills={user_coding_skills}/>
                                            </div>
                                        }

                                        {showKnowledge === true &&
                                            <div className="m-4">
                                                <Knowledge />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>


                            <div>
                                <div className="card profile_card_links">
                                    <div className="profile-sidebar-social">
                                        {socialLinks.hasOwnProperty('linkedIn') &&
                                            <a href={socialLinks.linkedIn} target="_blank">
                                                <i className="fab fa-linkedin"></i>
                                            </a>
                                        }

                                        {socialLinks.hasOwnProperty('github') &&
                                            <a href={socialLinks.github} target="_blank">
                                                <i className="fab fa-github"></i>
                                            </a>
                                        }

                                        {socialLinks.hasOwnProperty('twitter') &&
                                            <a href={socialLinks.twitter} target="_blank">
                                                <i className="fab fa-twitter"></i>
                                            </a>
                                        }

                                        {socialLinks.hasOwnProperty('instagram') &&
                                            <a href={socialLinks.instagram} target="_blank">
                                                <i className="fab fa-instagram"></i>
                                            </a>
                                        }

                                        {socialLinks.hasOwnProperty('quora') &&
                                            <a href={socialLinks.quora} target="_blank">
                                                <i className="fab fa-quora"></i>
                                            </a>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar;
