import '../style_pages/home.css';

const About = ({user_services}) => {

    console.log(user_services);
  return(
      <>
          <div className="col-md-9 col-sm-12">
              <div className="right-side-main-content">
                  <div className="about-us-content">
                      <h2>About Me</h2>
                      <p>I'm Creative Director and UI/UX Designer from Sydney, Australia, working in web development and
                          print media. I enjoy turning complex problems into simple, beautiful and intuitive designs.</p>
                      <p>My job is to build your website so that it is functional and user-friendly but at the same
                          time attractive. Moreover, I add personal touch to your product and make sure that is eye-catching
                          and easy to use. My aim is to bring across your
                          message and identity in the most creative way. I created web design for many famous brand companies.</p>
                  </div>
                  <div className="about-us-content mt-4">
                      <h2>What I'm Doing</h2>
                  </div>
                  <div className="card-colum-content">
                      <div className="row">
                          {user_services.length > 0 && user_services.map((item, index)=> {
                              return <div className="col-md-6 col-sm-12 text-center">
                                          <div className="project-card-content">
                                              <div className="card">
                                                  <div className="project-content">
                                                      <img
                                                          src="http://netgon.net/artstyles/v-card2/assets/icons/icon-design.svg"
                                                          alt=""/>
                                                      <h3>{item.name}</h3>
                                                      <p>{item.description}</p>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                          })}


                      </div>
                  </div>

                  <div className="about-us-content">
                      <h2>Testimonials</h2>
                  </div>
                  <div className="testimonials-man-content">
                      <div className="row">
                          <div className="col-md-12">
                              <div className="testimonials-card-content">
                                  <div className="card">
                                      <div className="testimonial-profile-content">
                                          <img src="http://netgon.net/artstyles/v-card2/assets/img/avatar-3.jpg" alt=""/>
                                          <h3>Jessica Miller</h3>
                                          <i className="fa-solid fa-quote-left"></i>
                                      </div>
                                      <p>Felicia was hired to create a corporate identity. We were very pleased
                                          with the work done. She has a lot of experience and is very concerned about the needs of client.</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="about-us-content mt-4">
                      <h2>Clients</h2>
                  </div>
                  <div className="client-main-content">
                      <div className="row">
                          <div className="col-md-3 col-sm-12">
                              <div className="client-company-img-content">
                                  <img src="http://netgon.net/artstyles/v-card2/assets/img/logo-partner.svg" alt="" className="img-fluid"/>
                              </div>
                          </div>
                          <div className="col-md-3 col-sm-12">
                              <div className="client-company-img-content">
                                  <img src="http://netgon.net/artstyles/v-card2/assets/img/logo-partner.svg" alt="" className="img-fluid"/>
                              </div>
                          </div>
                          <div className="col-md-3 col-sm-12">
                              <div className="client-company-img-content">
                                  <img src="http://netgon.net/artstyles/v-card2/assets/img/logo-partner.svg" alt="" className="img-fluid"/>
                              </div>
                          </div>
                          <div className="col-md-3 col-sm-12">
                              <div className="client-company-img-content">
                                  <img src="http://netgon.net/artstyles/v-card2/assets/img/logo-partner.svg" alt="" className="img-fluid"/>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}

export default About;