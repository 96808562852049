import PortfolioCard from './PortfolioCard';
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
const Portfolio = () => {
    const {user_portfolio} = useSelector(state => state.user);

    const [projects, setProjects]= useState([]);

    useEffect(() => {
        if(user_portfolio){
            setProjects(user_portfolio);
        }
    }, [user_portfolio])
    return(
        <>


            <div className="page_section_main_div">



                <div className="row">
                    <div className="col-md-12">
                        <h2 className="page_title">Portfolio</h2>
                    </div>
                    <div className="col-md-12 pb-4 pt-3">
                        <div className="row">
                            <div className="col-md-12"></div>

                            <div className="col-md-12">
                                <div className="row">
                                    {projects.length > 0 && projects.map((details, index) => {
                                        return <div className="col-md-4">
                                            <PortfolioCard details={details}/>
                                        </div>
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

export default Portfolio;