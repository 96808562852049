import {userActive, userStatue} from "../config/Constant";

export const check_email = (email) => {
    if(email){
        const pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if(!pattern.test(email)){
            return false;
        }else{
            return true;
        }
    }
}


export const imageSize=(image)=>{
    if(image.size >= 1157094){
        return false;
    }else {
        return true;
    }
}

export const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const imageDimension=(image,h,w)=>{
    let reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = function(e) {
        let image = new Image();
        image.src = e.target.result;
        image.onload = function () {
            let height = this.height;
            let width = this.width;
            if(parseInt(height) === parseInt(h) || parseInt(width) === parseInt(w)) {
                return true;
            }else{
                return false;
            }
        };
    }
}

export const imageType=(image)=>{
    if(image.type === 'image/png' || image.type === 'image/jpeg' || image.type === 'image/gif' || image.type === 'image/jpg'){
        return true;
    }else{
        return false;
    }
}

export const phone_number=(inputtxt)=>{
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

    const result =  re.test(inputtxt);
    return result;
}

export const UpdateState = (name,value, setValidations)=>{
    setValidations(prevState => ({
        ...prevState,
        [name]: value
    }));
}

export const encode_data=(code)=>{
    return btoa(code);
}

export const decode_data=(code)=>{
    return atob(code);
}

export const setLocalKey=(name,key)=>{
    localStorage.setItem(name, key);
}

export const getLocalKey=(name)=>{
    return localStorage.getItem(name);
}

export const clearLocalKey=()=>{
    return localStorage.clear();
}


export const checkIsLogin=()=>{
    if(getLocalKey(userStatue)){
        if(decode_data(getLocalKey(userStatue)) === "true"){
            return true;
        };
        return false;
    }
    return false;
}

export const checkIsActive=()=>{
    if(getLocalKey(userActive)){
        if(decode_data(getLocalKey(userActive)) === "true"){
            return true;
        };
        return false;
    }
    return false;
}

export const checkIsActiveKeys=(keys)=>{
    if(getLocalKey(keys)){
        if(decode_data(getLocalKey(keys)) === "true"){
            return true;
        };
        return false;
    }
    return false;
}

export const setCookie=(cname, cvalue)=> {
    const d = new Date();
    d.setTime(d.getTime() + (1*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const getCookieByName=(name)=>{
    // Split cookie string and get all individual name=value pairs in an array
    var cookieArr = document.cookie.split(";");

    for(var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");

        if(name === cookiePair[0].trim()) {
            // Decode the cookie value and return
            return decodeURIComponent(cookiePair[1]);
        }
    }
    // Return null if not found
    return null;
}
export const deleteAllCookies=()=>{
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
        var cookie = cookies[i];
        var eqPos = cookie.indexOf("=");
        var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}

export const get_path_name=(url)=>{
    return url.substring(url.lastIndexOf("/") + 1)
}

export const change_password_attr=()=>{
    let check_attr = document.getElementById('password_form').getAttribute('type');
    if(check_attr === 'password'){
        document.getElementById('password_form').setAttribute('type','text');
    }else {
        document.getElementById('password_form').setAttribute('type','password');
    }
}

export const change_password_attr_by_id=(id)=>{
    let check_attr = document.getElementById(id).getAttribute('type');
    if(check_attr === 'password'){
        document.getElementById(id).setAttribute('type','text');
    }else {
        document.getElementById(id).setAttribute('type','password');
    }
}


export const check_password=(password_n)=>{
    var myInput = document.getElementById("psw");
    var letter = document.getElementById("letter");
    var capital = document.getElementById("capital");
    var number = document.getElementById("number");
    var length = document.getElementById("length");


        // Validate lowercase letters
        var lowerCaseLetters = /[a-z]/g;
        if(password_n.value.match(lowerCaseLetters)) {
            letter.classList.remove("invalid");
            letter.classList.add("valid");
        } else {
            letter.classList.remove("valid");
            letter.classList.add("invalid");
        }

        // Validate capital letters
        var upperCaseLetters = /[A-Z]/g;
        if(password_n.value.match(upperCaseLetters)) {
            capital.classList.remove("invalid");
            capital.classList.add("valid");
        } else {
            capital.classList.remove("valid");
            capital.classList.add("invalid");
        }

        // Validate numbers
        var numbers = /[0-9]/g;
        if(password_n.value.match(numbers)) {
            number.classList.remove("invalid");
            number.classList.add("valid");
        } else {
            number.classList.remove("valid");
            number.classList.add("invalid");
        }

        // Validate length
        if(password_n.value.length >= 8) {
            length.classList.remove("invalid");
            length.classList.add("valid");
        } else {
            length.classList.remove("valid");
            length.classList.add("invalid");
        }
}

export const getStyleMargin =(e, styleName)=>{
    let styleValue = "";
    if (document.defaultView && document.defaultView.getComputedStyle) {
        styleValue = document.defaultView.getComputedStyle(e, "").getPropertyValue(styleName);
    } else if (e.currentStyle) {
        styleName = styleName.replace(/\-(\w)/g, function(strMatch, p1) {
            return p1.toUpperCase();
        });
        styleValue = e.currentStyle[styleName];
    }
    return styleValue;
}

export const  toggleSidebar=()=>{
    let e = document.getElementById('side_bar_left');
    let left = getStyleMargin(e, 'margin-left');
    if(left === "-300px"){
        document.getElementById("side_bar_left").style.marginLeft = "0px";
    }else {
        document.getElementById("side_bar_left").style.marginLeft = "-300px";
    }
}


export const  toggleRightSidebar=()=>{
    let e = document.getElementById('side_bar_right_div');
    let left = getStyleMargin(e, 'margin-right');
    if(left === "-250px"){
        document.getElementById("side_bar_right_div").style.marginRight = "0px";
    }else {
        document.getElementById("side_bar_right_div").style.marginRight = "-250px";
    }
}



export const toArrayList = (value) => {
  return {
      'value':value,
      'label':value
  }
}


export const closeModel = (id) => {
  document.getElementById(id).click();
}

export const removeObjectWithSlug=(arr, slug)=> {
    const objWithIdIndex = arr.findIndex((obj) => obj.slug === slug);

    if (objWithIdIndex > -1) {
        arr.splice(objWithIdIndex, 1);
    }

    return arr;
}

export const  sanitize_input=(input)=> {
    let list = ['/', '\\', '&', ';',',','.','^','!','@','#','$','%','*','(',')','+','=','|',':','?'];
    for(let i=0;i<list.length;i++) {
        input = input.replace(list[i], '');
    }
    return input
}



