import '../Netgon/style/netgon.css';
import MainPage from "./MainPage";


const NetgonIndex = () => {
  return(
      <>
        <div className="netgon-main-content">
            <MainPage/>
        </div>
      </>
  )
}

export default NetgonIndex;