// import '../style/theme/blue.css';


const Sidebar = ({userAboutProfileDetails}) => {

  return(
      <>
          <div className="sidebar-colum-content">
              <div className="card">
                  <div className="sidebar-profile-content text-center">
                      <img src={userAboutProfileDetails.avatar_image} alt=""/>
                      <h3>{userAboutProfileDetails.first_name}</h3>
                      <button><a href="sidebar">{userAboutProfileDetails.designation}</a></button>
                      <div className="sidebar-social-icon">
                          <i className="fa-brands fa-facebook-f"></i>
                          <i className="fa-brands fa-twitter"></i>
                          <i className="fa-brands fa-linkedin-in"></i>
                      </div>
                  </div>
                  <div className="sidebar-list-content">
                      <nav>
                          <ul>
                              <li><i className="fa-regular fa-calendar"></i> {userAboutProfileDetails.date_of_birth}</li>
                              <li><i className="fa-solid fa-location-dot"></i>{userAboutProfileDetails.nationality}</li>
                              <li><a href="sidebar"><i className="fa-regular fa-message"></i> {userAboutProfileDetails.email}</a></li>
                              <li><i className="fa-solid fa-mobile"></i>{userAboutProfileDetails.phone}</li>
                              {/*<li><a href="sidebar"><i className="fa-brands fa-skype"></i> Felecia_Brown</a></li>*/}
                          </ul>
                      </nav>
                      <button><a href="sidebar"><i className="fa-solid fa-download"></i> Download CV</a></button>
                  </div>
              </div>
          </div>
      </>
  )
}

export default Sidebar;