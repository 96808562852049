// import '../../style/theme/blue.css';

const Contact = () => {
  return(
      <>
          <div className="col-md-9 col-sm-12">
              <div className="right-side-main-content">
                  {/*<div className="about-us-content">*/}
                  {/*    <h2>Contact</h2>*/}
                  {/*</div>*/}
                  {/*<div className="contact-google-content">*/}
                  {/*    <div className="row">*/}
                  {/*        <div className="col-md-12">*/}
                  {/*            <div className="contact-google-map-content">*/}
                  {/*                <div className="card">*/}
                  {/*                    <h1>hrllo</h1>*/}
                  {/*                </div>*/}
                  {/*            </div>*/}
                  {/*        </div>*/}
                  {/*    </div>*/}
                  {/*</div>*/}
                  <div className="about-us-content mt-4">
                      <h2>Contact Form</h2>
                  </div>
                  <div className="contact-main-content">
                      <div className="row">
                          <div className="col-md-6 col-sm-12">
                              <div className="contact-input-content">
                                  <i className="fa-regular fa-user"></i>
                                  <input type="text" placeholder="Full name"></input>
                              </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                              <div className="contact-input-content">
                                  <i className="fa-solid fa-at"></i>
                                  <input type="text" placeholder="Email address"></input>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div className="contact-main-content">
                      <div className="row">
                          <div className="col-md-12 col-sm-12">
                              <div className="contact-message-input-content">
                                  <textarea placeholder="Your Message"/>
                              </div>
                              <div className="contact-button-content">
                                  <button><a href="sidebar"><i className="fa-solid fa-paper-plane"></i> Send Message</a></button>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}

export default Contact;