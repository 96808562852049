import {useSelector} from "react-redux";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import {aws_url} from "../../../../config/Constant";

const PortfolioModel = () => {

    const {user_portfolio_model} = useSelector(state => state.user);

    const images = [];

    if(user_portfolio_model.main_image){
        images.push(aws_url+user_portfolio_model.main_image);
    }

    if(user_portfolio_model.extra_image_one){
        images.push(aws_url+user_portfolio_model.extra_image_one);
    }

    if(user_portfolio_model.extra_image_two){
        images.push(aws_url+user_portfolio_model.extra_image_two);
    }

    if(user_portfolio_model.extra_image_three){
        images.push(aws_url+user_portfolio_model.extra_image_three);
    }

    return (
        <>
            <div className="modal fade " id="PortfolioModel">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content main_model">

                        <div className="modal-body">
                            <div>
                                {/*<div>*/}
                                {/*    <h2>{user_portfolio_model.title}</h2>*/}
                                {/*</div>*/}

                                <div className="model_data">
                                    <p>{user_portfolio_model.type}</p>
                                    <p>{user_portfolio_model.start_date} - {user_portfolio_model.end_date}</p>
                                </div>

                                <div>

                                    <Slide>

                                        {images.length > 0 && images.map((data, index)=> {
                                            return <div className="each-slide-effect">
                                                <div style={{'backgroundImage': `url(${data})`}}>

                                                </div>
                                            </div>
                                        })}

                                    </Slide>
                                </div>

                                <div className="model_title">
                                    <h2>{user_portfolio_model.title}</h2>
                                </div>

                                {user_portfolio_model.skills &&
                                    <div className="mt-3">
                                        {JSON.parse(user_portfolio_model.skills).length > 0 && JSON.parse(user_portfolio_model.skills).map((data, index)=> {
                                            return <div className="skills_bages">{data}</div>
                                        })}
                                    </div>
                                }

                                <div className="model_data_list">
                                    <div>
                                        <h4>Description</h4>

                                        <p>{user_portfolio_model.description}</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                                    </div>


                                    <div>
                                        <h4>Solution</h4>

                                        <p>{user_portfolio_model.solution}</p>
                                        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    </div>

                                    <div>
                                        <h4>Tasks</h4>

                                        <p>{user_portfolio_model.task}</p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        {/*<div className="modal-footer">*/}
                        {/*    <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>*/}
                        {/*</div>*/}

                    </div>
                </div>
            </div>
        </>
    )
}

export default PortfolioModel;