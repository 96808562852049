
const TechnicalExperience = ({user_coding_skills}) => {

    const getDetails = (data) => {
      return JSON.parse(data);
    }
  return(
      <>
          <div className="coding_div pb-4 mt-3 border-0">
              <ul className="sidebar-knowledge-list">
                  {user_coding_skills.length > 0 && user_coding_skills.map((knowledge, index) => {
                      return <li><i className="fas fa-check"></i><b>{knowledge.name}</b>
                                <div className="ml-4 mb-2 mt-2">
                                    {getDetails(knowledge.details).length > 0 && getDetails(knowledge.details).map((data, index)=>{
                                        return <div className="skills_bages mb-2" style={{ fontSize:'12px', padding:'3px 13px'}} key={index}>{data.text}</div>
                                    })}
                                </div>
                      </li>
                  })}
              </ul>
          </div>
      </>
)
}
export default TechnicalExperience;
