import ContactForm from "./ContactForm";
import ReactHelmet from "../../../../utility/ReactHelmet";
import {useSelector} from "react-redux";
const Contact = () => {

  const {userAboutProfileDetails, address,socialLinks} = useSelector(state => state.user);
  return(
      <>
          <ReactHelmet page_title="Contact"/>
          <div className="page_section_main_div">
              <div className="row">
                  <div className="col-md-12">
                      <h2 className="page_title">Contact information</h2>
                  </div>
                  <div className="col-md-12 pb-4 pt-3">
                      <div className="row">
                          <div className="col-md-4">
                              <div className="art-a art-card">
                                  <div className="info_div pb-3 border-0">
                                      <div className="text_div text-left">
                                          <p className="main_title_info">Residence:</p>
                                          <p className="main_title_info">City:</p>
                                          <p className="main_title_info">Age:</p>
                                      </div>
                                      <div className="text_div text-right">
                                          <p>Pakistan</p>
                                          <p>Rawalpindi</p>
                                          <p>25</p>
                                      </div>
                                  </div>
                              </div>
                          </div>

                          <div className="col-md-4">
                              <div className="art-a art-card">
                                  <div className="info_div pb-3 border-0">
                                      <div className="text_div text-left">
                                          <p className="main_title_info">Residence:</p>
                                          <p className="main_title_info">City:</p>
                                          <p className="main_title_info">Age:</p>
                                      </div>
                                      <div className="text_div text-right">
                                          <p>Pakistan</p>
                                          <p>Rawalpindi</p>
                                          <p>25</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>


                  <div className="col-md-12">
                      <h2 className="page_title">Get in touch  <small className="text-muted">I'm always open to discussing project</small></h2>
                  </div>
                  <div className="col-md-12">
                      <div className="art-a art-card">
                          <div role="form" className="wpcf7" id="wpcf7-f80-p76-o1" lang="en-US" dir="ltr">
                              <ContactForm/>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}

export default Contact;
