import ServiceCard from "./components/ServiceCard";
import {useSelector} from "react-redux";

const Services = () => {

  const {user_services} = useSelector(state => state.user);

  return(
      <>
          <div className="col-md-12 p-0">
              <h2 className="page_title">My Services</h2>
          </div>

          <div className="row pt-3">
              {user_services.map((services,index)=>{
                  return <div className="col-md-4">
                          return <ServiceCard services={services}/>
                  </div>
              })}
          </div>
      </>
  )
}

export default Services;