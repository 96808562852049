
const AchievementsExperience = ({user_achievements}) => {

  return(
      <>
        <div>
            <div>
                {user_achievements.length > 0  && user_achievements.map((education,index)=>{

                    return <div className="text-white experience_section_div">
                                <div className="start_div">
                                    <h2>{education.start_date} - {education.end_date}</h2>
                                    <h5>{education.company}</h5>

                                </div>
                                <div className="divider"></div>
                                <div className="end_div">
                                    <h3>{education.name}</h3>
                                    <p>{education.details}</p>
                                </div>
                            </div>
                })}

            </div>
        </div>
      </>
  )
}

export default AchievementsExperience;
