import '../../style/main.css';
import {aws_url} from "../../../../config/Constant";
import {useDispatch} from "react-redux";
import {setUserPortfolioModel} from "../../../../features/userSlice";

const PortfolioCard=({details})=>{

    const dispatch = useDispatch();


    const openModel = () => {
      dispatch(setUserPortfolioModel(details))
    }
    return(
        <>
            <div>


                <button onClick={()=>openModel()} type="button" className="btn btn-primary mb-3" data-toggle="modal" data-target="#PortfolioModel">
                    Open modal
                </button>

                <div className=" mb-4">
                    <div>
                        <div className="product-top">

                            <img style={{cursor: "pointer"}} className="img-fluid" src={aws_url + details.main_image}/>

                            <div className="overlay">
                                <div className="hover-effect-content">
                                    <div className="hover-effect-heading">
                                        <p>{details.type}</p>
                                        <h3>{details.title}</h3>
                                    </div>
                                    <div className="hover-effect-button">
                                        <a href={details.url} target="_blank"> <i
                                            className="fa-solid fa-arrow-right"></i> </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PortfolioCard;