const Hobbies = ({hobbies}) => {
    return (
        <>
            <div>
                {hobbies && hobbies.map((data, index) => {
                    return <span className="skills_bages mb-2" style={{ padding: '5px 13px',fontSize:'12px'}}>{data.name}</span>
                })}
            </div>
        </>
    )
}

export default Hobbies;