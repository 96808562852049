// firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyBUJHpJLtGyrl3NRO94yxTH0G9odLyOvn8",
    authDomain: "foliohub-dd316.firebaseapp.com",
    databaseURL: "https://foliohub-dd316-default-rtdb.firebaseio.com",
    projectId: "foliohub-dd316",
    storageBucket: "foliohub-dd316.appspot.com",
    messagingSenderId: "856994312285",
    appId: "1:856994312285:web:1a9414177710f00894ddb4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { database };
