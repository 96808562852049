import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";
import '../style/main.css';
import '../style/theme/black.css';
import '../style/mediaQuery.css';
import Sidebar from "../layouts/Sidebar";
import NavHeader from "../layouts/NavHeader";
import Home from "../pages/home/Home";
import About from "../pages/about/About";
import Navbar from "../layouts/Navbar";
import Portfolio from "../pages/portfolio/Portfolio";
import Contact from "../pages/contact/Contact";
import Resume from "../pages/resume/Resume";
import PortfolioModel from "../pages/portfolio/PortfolioModel";
import {useEffect} from "react";

const RoutingServices = ({color, profile_id, username}) => {

    useEffect(() => {
        const color_check = localStorage.getItem("theme_color");
        const existingStyle = document.getElementById("dynamic-theme");
        if (existingStyle) {
            existingStyle.remove();
        }

        if (color_check === "yellow") {
            import('../style/colors/yellow.css')
                .then(module => {
                    const link = document.createElement("link");
                    link.rel = "stylesheet";
                    link.id = "dynamic-theme";
                    link.href = module.default;
                    document.head.appendChild(link);
                });
        } else if (color_check === "red") {
            import('../style/colors/red.css')
                .then(module => {
                    const link = document.createElement("link");
                    link.rel = "stylesheet";
                    link.id = "dynamic-theme";
                    link.href = module.default;
                    document.head.appendChild(link);
                });
        }
    }, []);



    return(
        <>
            <div>

                {/*Portfolio Model*/}
                <PortfolioModel/>

                <div>
                    <div className="main_div_section">
                        <div className="side_bar_left" id="side_bar_left">
                            <Sidebar/>
                        </div>
                        <div className="main_bar_right">
                            <div className="main_div_right">
                                <div>
                                    <NavHeader/>
                                </div>
                                {/*Pages*/}

                                <BrowserRouter>
                                    <Routes>
                                        <Route path="/:username" element={<Home/>} exact></Route>
                                        <Route path="/:username/about" element={<About username={username}/>}></Route>
                                        <Route path="/:username/portfolio" element={<Portfolio/>}></Route>
                                        <Route path="/:username/contact" element={<Contact/>}></Route>
                                        <Route path="/:username/resume" element={<Resume/>}></Route>
                                    </Routes>
                                    <Navbar username={username}/>
                                </BrowserRouter>
                                {/*Pages*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RoutingServices;
