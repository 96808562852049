import {Helmet} from "react-helmet";
import {APP_Title} from "../config/Constant";

const ReactHelmet = ({page_title})=>{

    return(
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{page_title} - {APP_Title}</title>
            </Helmet>
        </>
    )

};
export default ReactHelmet;