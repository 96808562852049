
const EducationExperience = ({user_education}) => {

  return(
      <>
        <div>
            <div>
                {user_education.length > 0 && user_education.map((education,index)=>{

                    return <div className="text-white experience_section_div">
                        <div className="start_div">
                            <h2>{education.start_date} - {education.end_date}</h2>
                            <h5>{education.institution}</h5>

                        </div>
                        <div className="divider"></div>
                        <div className="end_div">
                            <h3>{education.name}</h3>
                            <p>{education.details}</p>

                            {(JSON.parse(education.courses)[0].tasks) ?
                                <div>
                                    <p>Courses</p>
                                    <ul>
                                        {(JSON.parse(education.courses)).map((points,index)=>{
                                            return <li>{points.tasks}</li>
                                        })}
                                    </ul>
                                </div>
                                :
                                <></>
                            }
                        </div>
                    </div>
                })}

            </div>
        </div>
      </>
  )
}

export default EducationExperience;
