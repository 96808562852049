
const CodingExperience = ({user_coding_skills}) => {
  return(
      <>
          <div className="coding_div pb-4 mt-3 border-0">

              {user_coding_skills.length > 0 && user_coding_skills.map((skills,index)=>{
                  return <div>
                              <div className="coding_text">
                                  <p>{skills.name}</p>
                                  <p>{skills.count}%</p>
                              </div>
                              <div className="progress coding-progress-bar">
                                  <div className="progress-bar " style={{ width:skills.count+'%'}}></div>
                              </div>
                          </div>
              })}

          </div>
      </>
  )
}
export default CodingExperience;
