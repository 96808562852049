import Navbar from "./layouts/Navbar";
import Sidebar from "./layouts/Sidebar";
import About from "./pages/about/About";
import '../Netgon/style/main.css';
import {useState} from "react";
import ResumeDetails from "./pages/resume/ResumeDetails";
import Portfolio from "./pages/portfolio/Portfolio";
import Contact from "./pages/contact/Contact";
import {useSelector} from "react-redux";

const MainPage = () => {

    const {userAboutProfileDetails, address, user_coding_skills, user_language, socialLinks, user_services} = useSelector(state => state.user);

    const [currentTab, setCurrentTab] = useState('About');

    const selectTab=(name)=>{
        setCurrentTab(name);
    }

    return(
        <>
            <div className="home_main_content">
                <div>
                    <div className="container">
                        <div className="row pt-5 pb-4">
                            <div className="col-md-12">
                                <Navbar selectTab={selectTab}/>
                                <div className="card border-0">
                                    <div className="colum-card-content">
                                        <div className="row">
                                            <div className="col-md-3 col-sm-12">
                                                <Sidebar userAboutProfileDetails={userAboutProfileDetails}/>
                                            </div>

                                            {currentTab === "About" && <About user_services={user_services}/> }
                                            {currentTab === "Resume" && <ResumeDetails/> }
                                            {currentTab === "Portfolio" && <Portfolio/> }
                                            {currentTab === "Contact" && <Contact/> }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default MainPage;