
const ReferenceExperience = ({user_reference}) => {

  return(
      <>
        <div>
            <div>
                {user_reference.length > 0 && user_reference.map((education,index)=>{

                    return <div className="text-white experience_section_div ml-3">

                                <div className="divider"></div>
                                <div className="end_div">
                                    <h3>{education.name} - ({education.job})</h3>
                                    <p>{education.company}</p>
                                    <p>{education.email} - {education.phone}</p>
                                    <p>{education.details}</p>
                                </div>
                            </div>
                })}

            </div>
        </div>
      </>
  )
}

export default ReferenceExperience;
