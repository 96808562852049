// import '../../style/theme/blue.css';
// import '../../style/theme/red.css';

import {useSelector} from "react-redux";

const ResumeDetails = () => {

    const {userAboutProfileDetails, address, user_coding_skills,
        user_education,
        user_achievements,
        user_certificates,
        user_reference,
        user_soft_skills,
        user_experience, user_language, user_knowledge} = useSelector(state => state.user);


    console.log(user_coding_skills);

    return(
        <>
            <div className="col-md-9 col-sm-12">
                <div className="right-side-main-content">
                    <div className="about-us-content">
                        <h2>Resume</h2>
                    </div>
                    <div className="eduction-main-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="eduction-heading-content">
                                    <a href=""><img src="http://netgon.net/artstyles/v-card2/assets/icons/icon-education.svg" alt=""/>Education</a>
                                </div>

                                {user_education.length > 0 && user_education.map((item, index) => (

                                    <>
                                        <div className="timeline_item">
                                            <h5>{item.institution}</h5>
                                            <span>{item.start_date} — {item.end_date}</span>
                                            <p>{item.details}</p>
                                        </div>
                                    </>
                                ))}
                            </div>
                        </div>
                    </div>

                    <div className="eduction-main-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="eduction-heading-content">
                                    <a href=""><img src="http://netgon.net/artstyles/v-card2/assets/icons/icon-experience.svg" alt=""/>Experience</a>
                                </div>
                                {user_experience.length > 0 && user_experience.map((item, index) => (
                                    <>
                                        <div className="timeline_item">
                                            <h5>{item.position}</h5>
                                            <span>{item.start_date} — {item.end_date}</span>
                                            <p>{item.details}</p>
                                        </div>
                                    </>
                                ))}


                            </div>
                        </div>
                    </div>
                    <div className="eduction-main-content">
                        <div className="row">
                            <div className="col-md-6 col-sm-12">
                                <div className="eduction-heading-content">
                                    <a href="">Languages</a>
                                </div>
                                <div className="language-content">
                                    {user_language.length > 0 && user_language.map((item, index) => (
                                        <form action="/action_page.php">
                                            <label htmlFor="customRange">{item.name}</label>
                                            <input type="range" className="custom-range" id="customRange"
                                                   name="points1"/>
                                        </form>
                                    ))}

                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <div className="eduction-heading-content">
                                    <a href="">Knowledge</a>
                                </div>
                                <div className="knowledge-nav-list-content">
                                    <nav>
                                        <ul>
                                            {user_knowledge.length > 0 && user_knowledge.map((item, index) => (
                                                <li><i className="fa-solid fa-check"></i> {item.name}</li>
                                            ))}

                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="eduction-main-content">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="eduction-heading-content mt-4">
                                    <a href="">Coding Skills</a>
                                </div>
                                <div className="skills-main-content">
                                    <div className="card">
                                        {user_coding_skills.length > 0 && user_coding_skills.map((item, index) => (
                                            <div className="progress">
                                                <div className="progress-bar" style={{width: item.count+"%"}}>
                                                    <div className="progress-span-content">
                                                        <span>{item.name}</span>
                                                        <span>{item.count}%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ResumeDetails;