const MainInterest = ({user_interest}) => {
    return(
        <>
            <div>
                {user_interest.length > 0 && user_interest.map((data, index) => {
                    return <span className="skills_bages mb-2">{data.name}</span>
                })}
            </div>
        </>
    )
}
export default MainInterest;