const LanguagesExperience = ({user_language}) => {

  return(
      <>
          <div className="languages_div_experience pb-2 mt-3">
              <div className="row">
                  {user_language.length > 0 && user_language.map((item, index) => (
                      <div className="col-md-3">
                          <div className="text_div text-center">
                              <div>
                                  <div className="progress_div_s yellow">
                              <span className="progress_div_s-left">
                              <span className="progress_div_s-bar"></span>
                              </span>
                                      <span className="progress_div_s-right">
                              <span className="progress_div_s-bar"></span>
                              </span>

                                      <div className="progress_div_s-value">{item.count}%</div>
                                  </div>
                              </div>
                              <p className="main_title_info">{item.name}</p>
                          </div>
                      </div>
                  ))}
              </div>

          </div>
      </>
  )
}
export default LanguagesExperience;
