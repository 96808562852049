import {useEffect, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";
import {closeRightSidebar, toggleRightSidebar} from "../utility/LayoutSetting";
import {capitalizeFirstLetter} from "../../../utility/Utility";
const Navbar = ({username}) => {
    const [currentPage, setCurrentPage] = useState("Home");
    const location = useLocation();
    const path_name = (location.pathname).replace('/','');


    useEffect(()=>{

        if(path_name){
            let get__ = path_name.split('/');
            if(get__.length > 1){
                setCurrentPage(get__[1]);
            }else{
                setCurrentPage('Home');
            }
            closeRightSidebar();
        }
    },[path_name]);

    return(
        <>
            <div>
                <div className="main_right_navbar">
                    <div>
                        <div>
                            <a href="#" className="art-info-bar-btn text-white" onClick={()=>toggleRightSidebar()}>
                                <i className="fas fa-bars"></i>
                            </a>
                        </div>
                        <div>
                            <p className="navbar_title_text">{capitalizeFirstLetter(currentPage)}</p>
                        </div>
                    </div>
                </div>

                <div className="nav_bar_right_side" id="side_bar_right_div">
                    <div className="mb-4">
                        <div>
                            <a href="#" className="art-info-bar-btn text-white" onClick={()=>toggleRightSidebar()}>
                                <i className="fas fa-close"></i>
                            </a>
                        </div>
                    </div>
                    <ul >
                        <li>
                            <NavLink to={`/${username}`} onClick={(e)=>setCurrentPage("Home")}>
                                Home
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={`/${username}/about`} onClick={(e)=>setCurrentPage("About")}>
                                About
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={`/${username}/resume`} onClick={(e)=>setCurrentPage("Resume")}>
                                Resume
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={`/${username}/portfolio`} onClick={(e)=>setCurrentPage("Portfolio")}>
                                Portfolio
                            </NavLink>
                        </li>

                        <li>
                            <NavLink to={`/${username}/contact`} onClick={(e)=>setCurrentPage("Contact")}>
                                Contact
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}
export default Navbar;
