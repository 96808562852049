import ReactHelmet from "../../../../utility/ReactHelmet";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {useEffect, useState} from "react";
import {s3_Link} from "../../../../config/Constant";

const About = ({username}) => {

  const {userAboutProfileDetails, address} = useSelector(state => state.user);
  const {portfolio_details, banner_content} = useSelector(state => state.user);

  const [userImage, setUserImage] = useState('');
  const [userImageType, setUserImageType] = useState('');

  const [user_experience_details, setUserExperience] = useState({});

    useEffect(()=>{
        if(portfolio_details.length > 0){
            setUserExperience(portfolio_details[0]);
        }
    },[portfolio_details]);

    useEffect(() => {
        let user_image = '';
        if(banner_content){
            if(banner_content.showHomeBanner === 1){
                user_image = s3_Link+banner_content.avatar_image;
                setUserImageType('banner');
            }else {
                user_image = userAboutProfileDetails.avatar_image;
                setUserImageType('profile');
            }
        }else {
            user_image = userAboutProfileDetails.avatar_image;
            setUserImageType('profile');
        }

        setUserImage(user_image);
    }, [banner_content]);

    return(
      <>
          <ReactHelmet page_title="About"/>

          <div className="page_section_main_div">
              <div className="row">
                  <div className="col-md-12">
                      <h2 className="page_title">About</h2>
                  </div>
                  <div className="col-md-12 pb-4 pt-3">
                  </div>
              </div>


              <div className="row arter_about_section">
                  <div className="col-md-12 mb-4 mt-4">
                      <div className="row">
                          <div className="col-md-6">
                              <div>
                                  <p>Hello There, Welcome to My Portfolio</p>
                                  <h1>I'm <span>{userAboutProfileDetails.first_name}</span></h1>
                                  <h1>A <span>{userAboutProfileDetails.designation}</span></h1>

                                  <div className="about_btn_links">
                                      <NavLink to={`/${username}/portfolio`}>See Portfolio</NavLink>
                                      <NavLink className="no_border_btn" to={`/${username}/contact`}>Contact Me</NavLink>
                                  </div>
                              </div>
                          </div>
                          <div className="col-md-6">
                              <div>
                                  {userImageType === 'banner' &&
                                      <div className="about_banner_image_div">
                                          <img src={userImage} className="about_banner_image"/>
                                      </div>

                                  }

                                  {userImageType === 'profile' &&
                                      <div className="about_profile_image_div">
                                          <img src={userImage} className="about_profile_image"/>
                                      </div>

                                  }
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

              <div className="row">
                  <div className="col-md-12 p-5 mt-4">
                      <div className="row mt-3 mb-3 pt-3">
                          <div className="col-md-3 col-6">
                              <div className="art-counter-frame">
                                  <div className="art-counter-box">
                                      <span className="art-counter">{user_experience_details?.total_experience}</span>
                                      <span className="art-counter-plus">+</span>
                                  </div>
                                  <h6 className="art-counter-text">
                                      <span>Years Experience</span>
                                  </h6>
                              </div>
                          </div>

                          <div className="col-md-3 col-6">
                              <div className="art-counter-frame">
                                  <div className="art-counter-box">
                                      <span className="art-counter">{user_experience_details?.completed_projects}</span>
                                  </div>
                                  <h6 className="art-counter-text">
                                      <span>Completed Projects</span>
                                  </h6>
                              </div>
                          </div>

                          <div className="col-md-3 col-6">
                              <div className="art-counter-frame">
                                  <div className="art-counter-box">
                                      <span className="art-counter">{user_experience_details?.happy_customers}</span>
                                  </div>
                                  <h6 className="art-counter-text">
                                      <span>Happy Customers</span>
                                  </h6>
                              </div>
                          </div>

                          <div className="col-md-3 col-6">
                              <div className="art-counter-frame">
                                  <div className="art-counter-box">
                                      <span className="art-counter">{user_experience_details?.honors_and_awards}</span>
                                      <span className="art-counter-plus">+</span>
                                  </div>
                                  <h6 className="art-counter-text">
                                      <span>Honors and Awards</span>
                                  </h6>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div className="col-md-12">
                      <h2 className="page_title experience_section_title mb-2 ml-3">About Me...</h2>
                      <div>

                          <div style={{padding: '0px 20px', borderRadius: '5px', color: 'white'}}
                               dangerouslySetInnerHTML={{__html: userAboutProfileDetails.portfolio_content}}
                          />
                      </div>
                  </div>
              </div>
          </div>
      </>
    )
}

export default About;
